import React, { useContext, useCallback, useEffect } from "react"
import * as styles from "./side-nav.module.scss"
import { AppContext } from "../context/context"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FaWindowClose } from "react-icons/fa"
import SocialIcons from "../social-icons/SocialIcons"
import SubNav from "./SubNav"
const SideNav = ({
  sideNavItems,
  logo,
  social,
  location,
  prPortal,
  prReviews,
  enableBuyOnline,
  buyOnlineLink,
}) => {
  const {
    isNavOpen,
    handleCloseNav,
    whereWeServiceSlug,
    whereWeServiceActive,
  } = useContext(AppContext)

  const buyOnlineSlug =
    enableBuyOnline.value[0].codename === "yes" ? buyOnlineLink : null

  const handleCloseNavEscKeyDown = useCallback(
    event => {
      const isEscKey = event.keyCode === 27
      if (isEscKey && isNavOpen) {
        handleCloseNav()
      }
    },
    [isNavOpen, handleCloseNav]
  )

  useEffect(() => {
    document.addEventListener("keydown", handleCloseNavEscKeyDown, false)

    return () => {
      document.removeEventListener("keydown", handleCloseNavEscKeyDown, false)
    }
  }, [handleCloseNavEscKeyDown])

  return (
    <>
      <aside
        className={
          isNavOpen ? `${styles.nav}` : `${styles.nav} ${styles.closeNav}`
        }
      >
        <div className={styles.sideNavHeader}>
          <img
            src={logo.value[0].fluid.src}
            alt="Logo"
            className={styles.logo}
          />
          <button onClick={handleCloseNav} className={styles.closeButton}>
            <FaWindowClose /> close
          </button>
        </div>

        <ul className={styles.links}>
          {sideNavItems.map(({ elements, id }, i) => {
            const { link_text, page, child_page } = elements
            if (child_page.value.length > 0) {
              return (
                <SubNav
                  prReviews={prReviews}
                  key={id}
                  parent={page}
                  kid={child_page}
                  link_text={link_text}
                  location={location}
                />
              )
            } else {
              const [links] = page.value
              const { slug } = links.elements
              if (
                prReviews.value[0].codename === "no" &&
                slug.value === "reviews"
              ) {
                return null
              } else if (links.__typename === "kontent_item_service_area") {
                return (
                  <li key={id}>
                    <Link
                      onClick={handleCloseNav}
                      to={
                        whereWeServiceActive === "true"
                          ? `/${whereWeServiceSlug}/${slug.value}`
                          : `/${slug.value}`
                      }
                      activeClassName={styles.linkActive}
                      className={styles.link}
                    >
                      {link_text.value}
                    </Link>
                  </li>
                )
              }
              return (
                <li key={i + 1 + id}>
                  <Link
                    onClick={handleCloseNav}
                    to={slug.value === "/" ? `${slug.value}` : `/${slug.value}`}
                    activeClassName={styles.linkActive}
                    className={styles.link}
                  >
                    {link_text.value}
                  </Link>
                </li>
              )
            }
          })}
          {prPortal.value[0].codename === "yes" && (
            <li>
              <a
                href="/login"
                onClick={handleCloseNav}
                className={`${styles.link} ${
                  location === "/login" && styles.linkActive
                }`}
              >
                Login
              </a>
            </li>
          )}
        </ul>
        {enableBuyOnline.value[0].codename === "yes" && (
          <AnchorLink
            className="primary-button"
            onAnchorLinkClick={handleCloseNav}
            to={`/${buyOnlineSlug}#bpco-container`}
            stripHash={true}
          >
            Buy Now
          </AnchorLink>
        )}
        <section className={styles.socialWrapper}>
          {social.value.map(({ elements, id }) => {
            return <SocialIcons key={id} icons={elements} />
          })}
        </section>
      </aside>
      <div
        onClick={handleCloseNav}
        className={isNavOpen ? styles.overlayOpen : ""}
        onKeyDown={handleCloseNavEscKeyDown}
        role="button"
        tabIndex="-1"
        aria-label="Clickable overlay to close side navigation"
      ></div>
    </>
  )
}

export default SideNav
