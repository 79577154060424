import React from "react"
import * as styles from "./secondary-header.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { AppContext } from "../context/context"
import { FaPhoneAlt } from "react-icons/fa"
const SecondaryHeader = ({
  phone,
  showReviews,
  showPortal,
  showBuyOnline,
  buyOnlineLink,
  pestroutesLogo,
  reviewsLogo,
  customCTA,
  navItems,
}) => {
  const { size } = React.useContext(AppContext)

  if (size < 768) {
    return (
      <section className={styles.secondaryHeader}>
        <a href={`tel: ${phone}`} className={styles.secondaryHeaderInner}>
          <span className={styles.phoneLink}>{phone}</span>
        </a>
        {showBuyOnline.codename === "no" ? null : (
          <Link to={`/${buyOnlineLink}`} className={styles.buyNow}>
            Buy Now
          </Link>
        )}
      </section>
    )
  } else {
    const prLogoPath = pestroutesLogo.nodes[0].childImageSharp.fixed
    const reviewLogoPath = reviewsLogo.nodes[0].childImageSharp.fixed
    return (
      <section className={`container ${styles.secondaryHeader}`}>
        <div className={styles.portalLinks}>
          {showPortal.codename === "yes" && (
            <a href="/login" className={styles.portalLink}>
              <Img
                fixed={prLogoPath}
                alt="Login Icon"
                className={styles.prLogo}
              />
              Client Login
            </a>
          )}
          {showPortal.codename === "yes" ||
            (showReviews.codename === "yes" && (
              <span className={styles.portalLinkDivider}>|</span>
            ))}

          {showReviews.codename === "yes" && (
            <Link to="/reviews" className={styles.portalLink}>
              <Img
                fixed={reviewLogoPath}
                alt="Reviews Icon"
                className={styles.reviewsIcon}
              />
              Customer Reviews
            </Link>
          )}
          {navItems &&
            navItems.value.length > 0 &&
            navItems.value.map(navItem => {
              return (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    navItem.elements.external_link.value === ""
                      ? `/${navItem.elements.page.value[0].elements.slug.value}`
                      : navItem.elements.external_link.value.slice(0, 1) === "/"
                      ? `${navItem.elements.external_link.value}`
                      : `/${navItem.elements.external_link.value}`
                  }
                  className={styles.portalLink}
                >
                  {navItem.elements.link_text.value}
                </a>
              )
            })}
        </div>
        <div className={styles.secondaryHeaderInner}>
          {size > 1038 && <span className="uppercase">{customCTA}</span>}
          <a href={`tel: ${phone}`} className={styles.phoneLink}>
            {phone}
            <div className={styles.phone}>
              <FaPhoneAlt />
            </div>
          </a>
          {showBuyOnline.codename === "no" && ""}
        </div>
      </section>
    )
  }
}
SecondaryHeader.defaultProps = {
  phone: "(888) 888-8888",
}
export default SecondaryHeader
