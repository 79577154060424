import React, { useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SecondaryHeader from "./secondary-header/SecondaryHeader"
import Header from "./main-header/Header"
import { AppContext } from "./context/context"
import { Helmet } from "react-helmet"
import { setTrackingCookies } from "../assets/js/helper"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"
import SideNav from "./side-nav/SideNav"
import Footer from "./footer/Footer"

const Layout = ({ children, location, mobilePreload }) => {
  const { size, height } = useContext(AppContext)

  useEffect(() => {
    setTrackingCookies()
  }, [])

  const data = useStaticQuery(getData)
  const { kontentItemSettings, pestroutesLogo, reviewsLogo, nodeEnvs } = data
  const { whereWeServiceActive, whereWeServiceSlug, googleID } = nodeEnvs
  const {
    secondary_header_navigation,
    company_name,
    primary_logo,
    footer_logo,
    corporate_head_office,
    primary_color,
    primary_alternate_color,
    secondary_color,
    secondary_alternate_color,
    accent_color,
    accent_alternate_color,
    link_color,
    enable_buy_online,
    pestroutes_reviews,
    pestroutes_portal,
    buy_online_page,
    header_navigation,
    side_navigation,
    footer_navigation,
    social_media,
    custom_cta_text,
    additional_offices,
    custom_css,
  } = kontentItemSettings.elements

  const { tracking_number } = corporate_head_office.value[0].elements
  const buyOnlineLink = buy_online_page.value[0].elements.slug.value
  if (size < 768) {
    return (
      <div className="wrapper">
        <Helmet
          meta={
            googleID && [
              { name: "google-site-verification", content: googleID },
            ]
          }
        >
          <style>{`:root{--primary: ${primary_color.value}; --primaryAlt:${primary_alternate_color.value}; --secondary: ${secondary_color.value};--secondaryAlt: ${secondary_alternate_color.value}; --accent: ${accent_color.value}; --accentAlt:${accent_alternate_color.value}; --link:${link_color.value}}`}</style>
          {custom_css.value.length > 0 && (
            <link rel="stylesheet" href={custom_css.value[0].url} />
          )}
          {mobilePreload && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
          <link
            rel="preload"
            as="image"
            href={primary_logo.value[0].fluid.src}
          />
        </Helmet>
        <div className="content">
          <Header
            logo={primary_logo.value}
            tracking_number={tracking_number?.value}
            whereWeServiceActive={whereWeServiceActive}
            whereWeServiceSlug={whereWeServiceSlug}
          />

          <SideNav
            sideNavItems={side_navigation.value}
            logo={primary_logo}
            social={social_media}
            prPortal={pestroutes_portal}
            prReviews={pestroutes_reviews}
            enableBuyOnline={enable_buy_online}
            buyOnlineLink={buyOnlineLink}
            location={location}
          />
          <main className="page-content">{children}</main>
        </div>
        <Footer
          logo={footer_logo}
          social={social_media}
          location={corporate_head_office}
          tracking_number={tracking_number.value}
          footerNavItems={footer_navigation.value}
          additionalOffices={additional_offices}
        />
      </div>
    )
  } else {
    return (
      <div className="wrapper">
        <Helmet>
          <style>{`:root{--primary: ${primary_color.value}; --primaryAlt:${primary_alternate_color.value}; --secondary: ${secondary_color.value};--secondaryAlt: ${secondary_alternate_color.value}; --accent: ${accent_color.value}; --accentAlt:${accent_alternate_color.value}; --link:${link_color.value}}`}</style>{" "}
          {custom_css.value.length > 0 && (
            <link rel="stylesheet" href={custom_css.value[0].url} />
          )}
        </Helmet>
        <div className="content">
          <SecondaryHeader
            phone={tracking_number.value}
            showReviews={pestroutes_reviews.value[0]}
            showPortal={pestroutes_portal.value[0]}
            showBuyOnline={enable_buy_online.value[0]}
            buyOnlineLink={buyOnlineLink}
            pestroutesLogo={pestroutesLogo}
            reviewsLogo={reviewsLogo}
            customCTA={custom_cta_text.value}
            navItems={secondary_header_navigation}
          />
          {/* height is a measure of the scroll distance */}
          {height > 66 ? (
            <Header
              logo={primary_logo.value}
              sticky={true}
              navItems={header_navigation}
            />
          ) : (
            <Header logo={primary_logo.value} navItems={header_navigation} />
          )}

          <SideNav
            sideNavItems={side_navigation.value}
            logo={primary_logo}
            social={social_media}
            prPortal={pestroutes_portal}
            prReviews={pestroutes_reviews}
            enableBuyOnline={enable_buy_online}
            buyOnlineLink={buyOnlineLink}
            location={location}
          />
          <main className="page-content">{children}</main>
        </div>
        <Footer
          logo={footer_logo}
          social={social_media}
          location={corporate_head_office}
          tracking_number={tracking_number.value}
          footerNavItems={footer_navigation.value}
          companyName={company_name.value}
          additionalOffices={additional_offices}
        />
      </div>
    )
  }
}

const getData = graphql`
  query getSettings {
    kontentItemSettings {
      elements {
        primary_logo {
          value {
            fluid(maxWidth: 200, quality: 100) {
              ...KontentAssetFluid_withWebp_noBase64
            }
            description
          }
        }
        footer_logo {
          value {
            fluid(maxWidth: 200, quality: 100) {
              ...KontentAssetFluid_withWebp_noBase64
            }
            description
          }
        }
        enable_buy_online {
          value {
            codename
          }
        }
        pestroutes_reviews {
          value {
            codename
          }
        }
        pestroutes_portal {
          value {
            codename
          }
        }
        primary_color {
          value
        }
        primary_alternate_color {
          value
        }
        secondary_color {
          value
        }
        secondary_alternate_color {
          value
        }
        accent_color {
          value
        }
        accent_alternate_color {
          value
        }
        link_color {
          value
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                name {
                  value
                }
                address1: address_line_1 {
                  value
                }
                address2: address_line_2 {
                  value
                }
                county {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                phone: phone_number {
                  value
                }
                tracking_number {
                  value
                }
                organization_membership {
                  value {
                    name
                  }
                }
                short_description {
                  value
                }
              }
            }
          }
        }
        additional_offices {
          value {
            ... on kontent_item_office {
              id
              elements {
                name {
                  value
                }
                address1: address_line_1 {
                  value
                }
                address2: address_line_2 {
                  value
                }
                county {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                phone: phone_number {
                  value
                }
                tracking_number {
                  value
                }
                organization_membership {
                  value {
                    name
                  }
                }
                short_description {
                  value
                }
              }
            }
          }
        }
        buy_online_page {
          value {
            ...PageItemFragment
          }
        }
        enable_alert_banner {
          value {
            codename
          }
        }
        header_navigation {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                link_text {
                  value
                }
                page {
                  value {
                    ...PageItemFragment
                    ... on kontent_item_service_area {
                      id
                      __typename
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                child_page {
                  value {
                    ... on kontent_item_navigation_item {
                      id
                      elements {
                        link_text {
                          value
                        }
                        page {
                          value {
                            ...PageItemFragment
                            ... on kontent_item_service_area {
                              id
                              __typename
                              elements {
                                slug {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        footer_navigation {
          value {
            ... on kontent_item_navigation_item {
              elements {
                child_page {
                  value {
                    ... on kontent_item_image_navigation {
                      id
                    }
                  }
                }
                link_text {
                  value
                }
                page {
                  value {
                    ...PageItemFragment
                  }
                }
              }
            }
          }
        }
        company_email {
          value
        }
        company_name {
          value
        }
        custom_cta_text {
          value
        }
        side_navigation {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                page {
                  value {
                    ...PageItemFragment
                    ... on kontent_item_service_area {
                      id
                      __typename
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                child_page {
                  value {
                    ... on kontent_item_navigation_item {
                      id
                      elements {
                        link_text {
                          value
                        }
                        page {
                          value {
                            ...PageItemFragment
                            ... on kontent_item_service_area {
                              id
                              __typename
                              elements {
                                slug {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                link_text {
                  value
                }
              }
            }
          }
        }
        site_name {
          value
        }
        social_media {
          value {
            ... on kontent_item_social_media_link {
              id
              elements {
                link {
                  value
                }
                social_media_outlet {
                  value {
                    codename
                  }
                }
              }
            }
          }
        }
        custom_css {
          value {
            url
          }
        }
      }
    }
    nodeEnvs {
      whereWeServiceActive
      whereWeServiceSlug
      recapKey
      googleID
    }
    pestroutesLogo: allFile(
      filter: {
        relativePath: { eq: "assets/images/customer-login-icon-white.png" }
      }
    ) {
      nodes {
        childImageSharp {
          fixed(quality: 100, width: 41, height: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    reviewsLogo: allFile(
      filter: { relativePath: { eq: "assets/images/pest-reviews-logo.png" } }
    ) {
      nodes {
        childImageSharp {
          fixed(quality: 100, width: 30, height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout
