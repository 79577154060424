// extracted by mini-css-extract-plugin
export var buyNow = "secondary-header-module--buy-now--6HzFi";
export var phone = "secondary-header-module--phone--1890z";
export var phoneLink = "secondary-header-module--phone-link--4uK2a";
export var portalLink = "secondary-header-module--portal-link--6pA8B";
export var portalLinkDivider = "secondary-header-module--portal-link-divider--5fqrH";
export var portalLinks = "secondary-header-module--portal-links--4bEFO";
export var prLogo = "secondary-header-module--pr-logo--4qaI5";
export var reviewsIcon = "secondary-header-module--reviews-icon--6oJ2Z";
export var secondaryHeader = "secondary-header-module--secondary-header--4Rnyz";
export var secondaryHeaderInner = "secondary-header-module--secondary-header-inner--7hSTh";